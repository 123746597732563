











































































import Vue from"vue";

export default Vue.extend({
  name: "UpdateStorageAreas",
  data: () => ({
    units: [],
    unitName: null,
    unitNewName: null,
    unitId: null,
    unitCount: null,
    page: 'updateStorage',
    access: 'private',
    storageLimit: 36,
  }),
  methods: {
    async updateName(id, name, count) {
      this.unitId = id;
      this.unitName = name;
      this.unitCount = count;
      this.unitNewName = name;
    },
    async editName() {
      try {
        this.unitNewName = this.unitNewName.replace(/[^0-9a-zA-Z \-\[\]\/()]/g, '');
        let emptyNameTest = this.unitNewName.replace(/[^0-9a-zA-Z\-\[\]\/()]/g, '');
        const badwords = require("badwords-list");
        const bannedwords = badwords.regex;
        if(this.unitNewName.match(bannedwords)){
          alert('You can not use this type of language on CTR!');
          this.unitNewName = this.unitName;
          return
        }
        if(!this.unitNewName || !emptyNameTest){
          alert('You must enter a name for your storage area.');
          this.unitNewName = this.unitName;
          return
        }
        await this.$http.post("/member/storage/update/", {
          id: this.unitId,
          content: this.unitNewName
        });
      } catch(error) {
        console.error(error);
      } finally {
        await this.getUnits();
        this.page = 'updateStorage';
        this.units = [];
        this.getUnits();
      }
    },
    async updateAccess() {
      console.log("UpdateAccess")
    },
    async getUnits(){
      this.units = [];
      try{
        const storageUnits = await this.$http.post(`/member/storage`, {
          member_id: this.$store.data.user.id,
        });
        storageUnits.data.storage.forEach(unit => {
          this.units.push(unit);
        });
      } catch (errorResponse: any) {
        console.error(errorResponse);
      }
    },
    changePage(page) {
      this.page = page;
    },
    async addStorage(){
      let newStorage;
      if(this.units.length < 10){
        newStorage = "Area0" + this.units.length;
      } else {
        newStorage = "Area" + this.units.length;
      } 
      try {
        await this.$http.post("/place/add_storage", {name: newStorage})
      } catch (error) {
        console.error(error);
      } finally {
        await this.getUnits();
      }
    },
  },
  mounted() {
    this.getUnits();
  },
});
