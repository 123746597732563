













































import Vue from "vue";
export default Vue.extend({
  name: "CityRoles",
  data: () => {
    return {
      accessLevel: null,
      cityRoles: [],
      status: ["Disabled", "Active"],
    };
  },
  methods: {
    async getAdminLevel(): Promise<void> {
      try{
        const access = await this.$http.get("/member/getadminlevel");
        this.accessLevel = access.data.accessLevel;
        this.accessCheck();
      } catch (e) {
        console.log(e);
      }
    },
    accessCheck() {
      if (!this.accessLevel.includes('admin')){
        this.$router.push({name: "restrictedaccess"});
      }
    },
    async getRoleList() {
      this.cityRoles = [];
      const roles = await this.$http.get('/admin/rolelist');
      this.cityRoles = roles.data.roles;
    },
  },
  created() {
    this.getAdminLevel();
  },
  mounted() {
    this.getRoleList();
  },
});
