












































































































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "EconomicPanel",
  data: () => {
    return {
      averageMoney: 0,
      averageObjects: 0,
      averageMallPrice: 0,
      averagePriceForSale: 0,
      highestMoney: 0,
      highestPriceForSale: 0,
      highestMallPrice: 0,
      totalUsers: 0,
      totalMoney: 0,
      totalUploads: 0,
      totalMallObjects: 0,
      totalPending: 0,
      totalAccepted: 0,
      totalRejected: 0,
      totalStocked: 0,
      totalDestocked: 0,
      totalObjects: 0,
      totalObjectsForSale: 0,
      totalBannedUsers: 0,
      totalJailedUsers: 0,
      totalColonies: 0,
      totalNeighborhoods: 0,
      totalBlocks: 0,
      totalFreeSpots: 0,
      totalHomes: 0,
      totalStorageAreas: 0,
      totalClubs: 0,
      totalPrivate: 0,
      activePlaces: [],
      activeMB: [],
      recentlyBanned: [],
      recentlyJailed: [],
      bansEndingSoon: [],
      latestTransactions: [],
      wealthiestUsers: [],
      newestUsers: [],
      latestHires: [],
      dailyUsers: 0,
      weeklyUsers: 0,
      monthlyUsers: 0,
      newMembersLastWeek: 0,
      newMembersLastMonth: 0,
      newMembersLastYear: 0,
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async getCommunityData() {
      const communityData = await this.$http.get('/admin/get-community-data');
      const path = communityData.data.results;
      // Activity Data
      if(!Number.isNaN(path.activity.totalDaily)){
        this.dailyUsers = path.activity.totalDaily[0].count;
      }
      if(!Number.isNaN(path.activity.totalWeekly)){
        this.weeklyUsers = path.activity.totalWeekly[0].count;
      }
      if(!Number.isNaN(path.activity.totalMonthly)){
        this.monthlyUsers = path.activity.totalMonthly[0].count;
      }
      if(!Number.isNaN(path.activity.newWeekly)){
        this.newMembersLastWeek = path.activity.newWeekly[0].count;
      }
      if(!Number.isNaN(path.activity.newMonthly)){
        this.newMembersLastMonth = path.activity.newMonthly[0].count;
      }
      if(!Number.isNaN(path.activity.newYearly)){
        this.newMembersLastYear = path.activity.newYearly[0].count;
      }
      
      // Security Data
      this.recentlyBanned = path.security.recentBan;
      this.recentlyJailed = path.security.recentJail;
      this.bansEndingSoon = path.security.banEnding;
      if(!Number.isNaN(path.security.totalBanned)){
        this.totalBannedUsers = path.security.totalBanned[0].count;
      }
      
      if(!Number.isNaN(path.security.totalJailed)) {
        this.totalJailedUsers = path.security.totalJailed[0].count;
      }
      // Place Data
      if(!Number.isNaN(path.place.totalColonies)){
        this.totalColonies = path.place.totalColonies[0].count;
      }
      if(!Number.isNaN(path.place.totalHoods)){
        this.totalNeighborhoods = path.place.totalHoods[0].count;
      }
      if(!Number.isNaN(path.place.totalBlocks)){
        this.totalBlocks = path.place.totalBlocks[0].count;
      }
      if(!Number.isNaN(path.place.totalFreeSpots)){
        this.totalFreeSpots = path.place.totalFreeSpots[0].count;
      }
      if(!Number.isNaN(path.place.totalHomes)){
        this.totalHomes = path.place.totalHomes[0].count;
      }
      if(!Number.isNaN(path.place.totalStorages)){
        this.totalStorageAreas = path.place.totalStorages[0].count;
      }
      if(!Number.isNaN(path.place.totalClubs)){
        this.totalClubs = path.place.totalClubs[0].count;
      }
      if(!Number.isNaN(path.place.totalPrivate)){
        this.totalPrivate = path.place.totalPrivate[0].count;
      }

      // Member Data
      if(!Number.isNaN(path.member.totalMembers)){
        this.totalUsers = path.member.totalMembers[0].count;
      }
      this.newestUsers = path.member.newestMembers;

      // Money Data
      this.averageMoney = path.money.averageBalance[0].balance;
      this.highestMoney = path.money.topBalance;
      this.totalMoney = path.money.totalBalance[0].balance;
      this.wealthiestUsers = path.money.wealthiestUsers;
      this.latestTransactions = path.money.latestTransactions;

      // Object Data
      this.totalObjects = path.object.instances.totalUserObjects;
      this.averageObjects = Math.round(this.totalObjects / this.totalUsers);
      if(!Number.isNaN(path.object.instances.totalForSale)){
        this.totalObjectsForSale = path.object.instances.totalForSale[0].count;
      }
      if(!Number.isNaN(path.object.instances.highestUserPrice)){
        this.highestPriceForSale = path.object.instances.highestUserPrice[0].price;
      }
      if(!Number.isNaN(path.object.instances.averageUserPrice)){
        this.averagePriceForSale = path.object.instances.averageUserPrice[0].price;
      }
      if(!Number.isNaN(path.object.mall.averagePrice)){
        this.averageMallPrice = Math.round(path.object.mall.averagePrice[0].price);
      }
      if(!Number.isNaN(path.object.mall.highestPrice)){
        this.highestMallPrice = Math.round(path.object.mall.highestPrice[0].price);
      }
      if(!Number.isNaN(path.object.mall.totalMallObjects)){
        this.totalMallObjects = Math.round(path.object.mall.totalMallObjects[0].count);
      }
      if(!Number.isNaN(path.object.mall.totalStocked)){
        this.totalStocked = Math.round(path.object.mall.totalStocked[0].count);
      }
      if(!Number.isNaN(path.object.mall.totalUploaded)){
        this.totalUploads = Math.round(path.object.mall.totalUploaded[0].count);
      }

      // Active places
      this.activePlaces = path.messages.chat;
      this.activeMB = path.messages.messageboard;
      // New Hires
      this.latestHires = path.hiring.latestRoleHire
    },
  },
  created() {
    this.getCommunityData();
  },
  mounted() {
  },
});
