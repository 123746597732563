























import Vue from "vue";
export default Vue.extend({
  name: "UserStorageAreas",
  data: () => {
    return {
      totalCount: 0,
      places: [],
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      pageNum: 1,
      pages: [],
      search: "",
    };
  },
  methods: {
    async searchPlaces(): Promise<any> {
      this.places = [];
      this.pages = [];
      this.totalCount = 0;
      try {
        await this.$http.get(`/admin/userplacessearch/`, {
          id: this.$route.params.id,
          type: 'storage',
        }).then((res) => {
          this.places = res.data.results.places;
          this.totalCount = res.data.results.total[0].count;
        });
      } catch (error) {
        console.log(error);
      }
    },
    setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      this.searchPlaces();
    },
  },
  async created() {
    await this.searchPlaces();
  },
  mounted() {
  },
});
