

































































































import Vue from "vue";
export default Vue.extend({
  name: "Transactions",
  data: () => {
    return {
      accessLevel: null,
      totalCount: 0,
      userTransactions: [],
      finalTransactions: [],
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      user: null,
      type: "object-sell",
      reasonDisplay: [
        'Daily Credit',
        'Home Purchase',
        'Home Refund',
        'User Object Sells', 
        'Mall Object Upload',
        'Mall Reject Refund',
        'Mall Object Restock',
        'Mall Unsold Refund',
        'Mall Purchase',
        'Mall Sold',
      ],
      reasons: [
        'daily-credit',
        'home-purchase',
        'home-refund',
        'object-sell', 
        'object-upload',
        'object-upload-refund',
        'object-restock',
        'object-unsold-instance-refund',
        'object-purchase',
        'object-profit',
      ],
    };
  },
  methods: {
    async getAdminLevel(): Promise<void> {
      try{
        const access = await this.$http.get("/member/getadminlevel");
        this.accessLevel = access.data.accessLevel;
        this.accessCheck();
      } catch (e) {
        console.log(e);
      }
    },
    accessCheck() {
      if (!this.accessLevel.includes('security')){
        this.$router.push({name: "restrictedaccess"});
      }
    },
    async getTransactions() {
      this.userTransactions = [];
      this.finalTransactions = [];
      this.totalCount = 0;
      await this.$http.get('/admin/transactions', {
        type: this.type,
        limit: this.limit,
        offset: this.offset,
      }).then((result) => {
        this.userTransactions = result.data.returnResults[0];
        this.totalCount = result.data.returnResults[1][0].count;
      });
      this.userTransactions.forEach((result) => {
        const index = this.reasons.indexOf(result.reason);
        result.reason_display = index;
        this.finalTransactions.push(result);
      })
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getTransactions();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getTransactions();
      this.showNext = true;
    },
  },
  created() {
    this.getAdminLevel();
  },
  mounted() {
    this.getTransactions();
  },
});
