























import Vue from "vue";

export default Vue.extend({
  name: "ClubDoor",
  data: () => {
    return {
      loaded: false,
      showButton: false,
      status: "none",
    };
  },
  methods: {
    async join() {
      await this.$http.post("/club/join", { clubId: this.$route.params.id });
      this.showButton = false;
      this.getMembership();
      return;
    },
    async getMembership(): Promise<void> {
      await this.$http.get("/club/status", { clubId: this.$route.params.id }).then((response) => {
        console.log(response)
        this.status = response.data.status;
        if(this.status === 'none'){
          this.showButton = true;
        }
        if(this.status === 'member'){
          this.$router.push(`/club/${this.$route.params.id}`);
        }
      });
    },
  },
  created() {
    this.getMembership();
  },
  mounted() {
    this.loaded = true;
  },
});
