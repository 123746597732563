















import Vue from "vue";
import CommunityData from './panels/data.vue';
export default Vue.extend({
  components: { CommunityData },
  name: "overview",
  data: () => {
    return {
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
  },
  created() {
  },
  mounted() {
  },
});
