






























import Vue from "vue";

export default Vue.extend({
  name: "admin",
  data: () => {
    return {
      accessLevel: [],
    };
  },
  methods: {
    async getAdminLevel(): Promise<void> {
      try{
        const access = await this.$http.get("/member/getadminlevel");
        this.accessLevel = access.data.accessLevel;
        this.accessCheck();
      } catch (e) {
        console.log(e);
      }
    },
    accessCheck() {
      if (this.accessLevel.length <= 0){
        this.$router.push({name: "restrictedaccess"});
      }
    },
  },
  created() {
    this.getAdminLevel();
  },
});
