






















































































import Vue from "vue";
export default Vue.extend({
  name: "TransactionHistory",
  data: () => {
    return {
      transactions: [],
      limit: 10,
      offset: 0,
      pageNum: 1,
      pages: [],
      showNext: false,
      totalCount: 0,
      reasonDisplay: [
        'Daily Credit',
        'Home Purchase',
        'Home Refund',
        'User Object Sells', 
        'Mall Object Upload',
        'Mall Reject Refund',
        'Mall Object Restock',
        'Mall Unsold Refund',
        'Mall Item Purchase',
        'Mall Item Sold',
      ],
      reasons: [
        'daily-credit',
        'home-purchase',
        'home-refund',
        'object-sell', 
        'object-upload',
        'object-upload-refund',
        'object-restock',
        'object-unsold-instance-refund',
        'object-purchase',
        'object-profit',
      ],
    };
  },
  methods: {
    async getTransactions() {
      this.transactions = [];
      this.pages = [];
      this.totalCount = 0;
      const results = await this.$http.get(`/admin/transactions/${this.$route.params.id}`, {
        limit: this.limit,
        offset: this.offset,
      })
      this.transactions = results.data.results.transactions;
      this.totalCount = results.data.results.total[0].count;
      let pages = Math.ceil(this.totalCount/this.limit);
        for(let i = 1; pages >= i; i++){
          this.pages.push(i);
        }
        if(this.pageNum > pages && this.totalCount > 0){
          this.pageNum = 1;
          this.offset = 0;
          setTimeout(this.getTransactions, 1000);
        }
    },
    formatReason(data) {
      const index = this.reasons.indexOf(data);
      if(index === -1) {
        return "Weekly Role Credit";
      } else {
        return this.reasonDisplay[index];
      }
    },
    setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      this.getTransactions();
    },
    setPageNumber(value){
      this.pageNum = value;
      this.offset = this.pageNum * this.limit - this.limit;
      this.getTransactions();
    },
    async next() {
      this.offset = this.pageNum * this.limit;
      this.pageNum++
      await this.getTransactions();
    },
    async back() {
      this.pageNum--
      this.offset = this.pageNum * this.limit - this.limit;
      await this.getTransactions();
      this.showNext = true;
    },
  },
  created() {
    this.getTransactions();
  },
  mounted() {
  },
});
