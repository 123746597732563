













































































































import Vue from "vue";
export default Vue.extend({
  name: "UserMallUploads",
  data: () => {
    return {
      totalCount: 0,
      objects: [],
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      pageNum: 1,
      pages: [],
      search: "",
    };
  },
  methods: {
    async searchObjects(): Promise<any> {
      this.objects = [];
      this.pages = [];
      this.totalCount = 0;
      try {
        await this.$http.get(`/mall/user/${this.$route.params.id}`, {
          limit: this.limit,
          offset: this.offset,
        }).then((res) => {
          this.objects = res.data.object.objects;
          this.totalCount = res.data.object.total[0].count;
        });
        let pages = Math.ceil(this.totalCount/this.limit);
        for(let i = 1; pages >= i; i++){
          this.pages.push(i);
        }
        if(this.pageNum > pages && this.totalCount > 0){
          this.pageNum = 1;
          this.offset = 0;
          setTimeout(this.searchObjects, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      this.searchObjects();
    },
    setPageNumber(value){
      this.pageNum = value;
      this.offset = this.pageNum * this.limit - this.limit;
      this.searchObjects();
    },
    async next() {
      this.offset = this.pageNum * this.limit;
      this.pageNum++
      await this.searchObjects();
    },
    async back() {
      this.pageNum--
      this.offset = this.pageNum * this.limit - this.limit;
      await this.searchObjects();
      this.showNext = true;
    },
  },
  async created() {
    await this.searchObjects();
  },
  mounted() {
  },
});
