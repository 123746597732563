











































































































import Vue from "vue";
export default Vue.extend({
  name: "UserObjectSearch",
  data: () => {
    return {
      totalCount: 0,
      objects: [],
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      pageNum: 1,
      pages: [],
      search: "",
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async searchObjects(): Promise<any> {
      this.objects = [];
      this.pages = [];
      this.totalCount = 0;
      try {
        const searched =  await this.$http.get("/admin/object-instances/", {
            limit: this.limit,
            offset: this.offset,
          });
          this.objects = searched.data.returnResults[0].object;
          this.totalCount = searched.data.returnResults[0].total;
          let pages = Math.ceil(this.totalCount/this.limit);
          for(let i = 1; pages >= i; i++){
            this.pages.push(i);
          }
          if(this.pageNum > pages && this.totalCount > 0){
            this.pageNum = 1;
            this.offset = 0;
            setTimeout(this.searchObjects, 1000);
          }
      } catch (error) {
        console.log(error);
      }
    },
    openUpdater(objectId){
      window.open("/#/admin/update-object/" + objectId, "targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
    setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      this.searchObjects();
    },
    setPageNumber(value){
      this.pageNum = value;
      this.offset = this.pageNum * this.limit - this.limit;
      this.searchObjects();
    },
    clearInput() {
      this.search = "";
      this.searchObjects();
    },
    async next() {
      this.offset = this.pageNum * this.limit;
      this.pageNum++
      await this.searchObjects();
    },
    async back() {
      this.pageNum--
      this.offset = this.pageNum * this.limit - this.limit;
      await this.searchObjects();
      this.showNext = true;
    },
  },
  async created() {
    await this.searchObjects();
  },
});
