




















































































































import Vue from "vue";

export default Vue.extend({
  name: "ObjectCatalog",
  data: () => {
    return {
      page: 'search',
      objects: [],
      total: 0,
      showError: false,
      error: '',
      success: '',
      showSuccess: false,
      loaded: false,
      limit: 10,
      offset: 0,
      showNext: true,
      pageNum: 1,
      pages: [],
      object_id: 0,
      objectName: null,
      objectDirectory: null,
      objectImage: null,
      creator: null,
      name: null,
      price: 0,
      objectLimit: '',
      qtySold: 0,
      uploadDate: null,
      forSale: 0,
      publicPlaces: 0,
      displayOffer: false,
    };
  },
  methods: {
    async getObjects(): Promise<void> {
      this.objects = [];
      this.pages = [];
      try {
        const object = await this.$http.get(`/mall/object-catalog`, {
          limit: this.limit,
          offset: this.offset,
        });
        this.objects = object.data.results.objects;
        this.total = object.data.results.total[0].count;
        this.showSuccess = true;
        let pages = Math.ceil(this.total/this.limit);
        for(let i = 1; pages >= i; i++){
          this.pages.push(i);
        }
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
    formatName(name) {
      const formattedName = name.split('(');
      return formattedName[0];
    },
    formatDate(date){
      return new Date(date)
        .toLocaleString('en-US', {
          month: 'numeric',
          day:'numeric',
          year: 'numeric'
        })
    },
    async showDetails(object) {
      const index = this.objects.indexOf(object);
      const details = this.objects[index];
      this.object_id = details.id;
      this.forSale = details.forSale;
      this.publicPlaces = details.publicPlaces;
      this.objectName = details.name;
      this.objectDirectory = details.directory;
      this.objectImage = details.image;
      this.name = details.name;
      this.price = details.price;
      this.creator = details.username;
      if(details.limit && details.limit >= 1){
        this.objectLimit ="1/"+details.limit;
      } else {
        this.objectLimit = 'Unlimited';
      }
      this.qtySold = details.instances;
      this.uploadDate = this.formatDate(details.created_at);
      this.page = 'details';
    },
    backToSearch() {
      this.object_id = 0;
      this.objectName = null;
      this.objectDirectory = null;
      this.objectImage = null;
      this.name = null;
      this.price = 0;
      this.creator = null;
      this.objectLimit = '';
      this.qtySold = 0;
      this.uploadDate = null;
      this.page = 'search';
    },
    async setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      await this.getObjects();
    },
    async setPageNumber(value){
      this.pageNum = value;
      this.offset = this.pageNum * this.limit - this.limit;
      await this.getObjects();
    },
    async next() {
      this.offset = this.pageNum * this.limit;
      this.pageNum++
      await this.getObjects();
    },
    async back() {
      this.pageNum--
      this.offset = this.pageNum * this.limit - this.limit;
      await this.getObjects();
      this.showNext = true;
    },
    async makeOffer(){
      // Add functionality to make an offer.
      // Hid button until functionality is added.
    }
  },
  created() {
    this.getObjects();
  },
});
