






























































import Vue from "vue";
export default Vue.extend({
  name: "InfoView",
  data() {
    return {
      altAccounts: null,
      username: null,
      immigration: null,
      firstName: null,
      lastName: null,
      money: 0,
      xp: 0,
      lastLogin: null,
      lastWeeklyPayout: null,
    };
  },
  props: ["accessLevel"],
  methods:{
    async getinfo(): Promise <void>{
      await this.$http.get(`/member/info/${this.$route.params.id}`)
        .then((response) => {
          const info = response.data.memberInfo;
          this.username = info.username;
          this.immigration = info.immigrationDate;
          this.firstName = info.firstName;
          this.lastName = info.lastname;
          this.money = info.walletBalance;
          this.xp = info.xp;
          this.lastLogin = info.last_daily_login_credit;
          this.lastWeeklyPayout = info.last_weekly_role_credit;
        });
    },
  },
  async created() {
    await this.getinfo();
  },
});
