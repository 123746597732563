








































































import Vue from "vue";

export default Vue.extend({
  name: "StorageUnit",
  data: () => ({
    unitName: null,
    unitOwner: null,
    unitId: null,
    objects: [],
    storageObjects: [],
    backpack: [],
    moveToBackpack: [],
    moveToStorage: [],
    showError: false,
    error: "",
    showSuccess: false,
    showStorage: false,
    maxHeight: '180px',
    panelMaxHeight: '340px',
    areaDeleted: false,
  }),
  methods: {
    async getStorageObjects(){
      this.moveToBackpack = [];
      this.moveToStorage = [];
      this.storageObjects = []
      const storage = await this.$http.get(`/place/${this.unitId}/object_instance`);
      this.storageObjects = storage.data.object_instance;
    },
    async getBackpackObjects(){
      this.moveToBackpack = [];
      this.moveToStorage = [];
      this.backpack = [];
      const response = await this.$http.get(`/member/backpack/${this.$store.data.user.username}`);
      this.backpack = response.data.objects;
    },
    async getOwnerDetails(){
      console.log("Get Owner Details", parseInt(this.unitId))
      const place = await this.$http.get(`/place/by_id/${this.unitId}`);
      this.unitName = place.data.place.name;
      this.unitOwner = place.data.place.member_id;
      if(this.unitOwner !== this.$store.data.user.id){
        this.maxHeight = '585px'
        this.panelMaxHeight = '680px'
      }
    },
    async moveObjectsToBackpack(){
      try{
        if(this.moveToBackpack.length >= 1){
          await this.$http.post('/object_instance/backpack', {
            id: this.moveToBackpack
          });
          this.getStorageObjects();
          this.getBackpackObjects();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async moveObjectsToStorage(){
      try{
        if(this.moveToStorage.length >= 1){
          await this.$http.post('/object_instance/storage', {
            id: this.moveToStorage,
            place_id: this.unitId
          });
          this.getStorageObjects();
          this.getBackpackObjects();
        }
      } catch (error) {
        console.log(error);
      }
    },
    moveAllBackpack(){
      if(confirm("This will remove all the items in this storage unit and place them in your backpack.\n\nDo you want to continue?")){
        this.storageObjects.forEach(obj => {
          this.moveToBackpack.push(obj.id);
        })
        this.moveObjectsToBackpack();
      }
    },
    moveAllStorage(){
      if(confirm("This will remove all the items from your backpack and place them in this storage unit.\n\nDo you want to continue?")){
        this.backpack.forEach(obj => {
          this.moveToStorage.push(obj.id);
        })
        this.moveObjectsToStorage();
      }
    },
    objectOpener(id) {  
      window.open("/#/object/"+id, "_targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
    async deleteStorageArea(){
      try{
        if(this.storageObjects.length === 0){
          await this.$http.post('/place/delete_storage', {
            id: this.unitId,
          }).then(() => {
            this.areaDeleted = true;
          })
        }
    } catch(error) {
      console.log(error);
    }
    },
    async checkStorageStatus() {
      const place = await this.$http.get(`/place/by_id/${this.unitId}`);
      if(place.data.place.status === 0){
        this.areaDeleted = true;
      }
    }
  },
  created() {
    this.unitId = this.$route.params.id;
    this.checkStorageStatus();
  },
  mounted() {
    this.getStorageObjects();
    this.getBackpackObjects();
    this.getOwnerDetails();
  },
});
